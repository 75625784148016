import axios from 'axios';
import qs from 'query-string';

import {
  SERVER_URL,
  ROOT_URL,
  GSPLUS_SIGNUP_EN_URL,
  GSPLUS_SIGNUP_FR_URL,
} from '../../utils/environment';

import { COMPLETED_SIGNIN, logEvent, setUserId } from '../../utils/amplitude';

const redirectToApp = (token, url, params = null) => {
  const inkblotUrl = `${url}/redirect/${token}${
    params ? `?${qs.stringify(params)}` : ''
  }`;
  window.location.replace(inkblotUrl);
};

const redirect = (response, url, locale) => {
  const userId = response.data.user_id;

  setUserId(userId);
  logEvent(COMPLETED_SIGNIN);

  const { access_token: token, gsplus_migration } = response.data;

  if (gsplus_migration === true) {
    window.location.assign(
      locale === 'fr' ? GSPLUS_SIGNUP_FR_URL : GSPLUS_SIGNUP_EN_URL,
    );
    return;
  }

  if (token) {
    // redirect partner to registration on login with existing account
    if (window.location.search.includes('partner_registration=true')) {
      redirectToApp(token, url, { partner_registration: true });
    } else {
      redirectToApp(token, url, null);
    }
  }
};

const signIn = ({ email, locale, password, source }) => {
  const body = {
    email,
    locale,
    password,
    source,
  };
  return axios
    .post(`${SERVER_URL}/api/login`, body)
    .then((mainResponse) => {
      redirect(mainResponse, ROOT_URL, locale);
    })
    .catch((mainError) => ({ error: mainError.response.data.error }));
};
export default signIn;
